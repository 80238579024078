import * as React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';

/**
 * Register components for hydration
 * @param component
 * @param componentName
 * @param dontHydrate - Do not attempt to hydrate SSR (only use if client is dramatically different then SSR in default use-case
 */
export const registerComponent = (component: any, componentName: string, dontHydrate?: boolean) => {
    const elements = document.querySelectorAll<HTMLDivElement>('[data-react]');
    for (const element of Array.from(elements)) {
        const name = element.dataset.react;
        if (name.indexOf(componentName + '_') === 0) {
            if (dontHydrate) {
                const root = createRoot(element);
                root.render(React.createElement(component, window.ReactData[name]));
            } else {
                hydrateRoot(element, React.createElement(component, window.ReactData[name]));
            }
        }
    }
};
